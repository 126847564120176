<template>
  <div class="teacher">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>教师管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-bar">
      <div class="left">
        <el-form :inline="true" :model="query" class="search-form-inline">
          <el-form-item>
            <el-input v-model="query.title" placeholder="请输入手机号" clearable @clear="getDataList"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDataList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <el-button type="primary" @click="addUser">添加新教师账号</el-button>
      </div>
    </div>
    <div class="page-content">
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column fixed prop="id" label="#" width="60"></el-table-column>
        <el-table-column prop="m_id" label="混合ID" width="120"></el-table-column>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="user_username" label="归属管理帐号" v-if="this.$store.state.userInfo.role === 0"></el-table-column>
        <el-table-column prop="campus_title" label="归属校区"></el-table-column>
        <el-table-column prop="total_time" label="总在线时长" width="200">
          <template slot-scope="scope">
            <el-tag type="success" effect="plain">{{ toTime(scope.row.total_time) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="last_ip" label="最近登录IP"></el-table-column>
        <el-table-column prop="last_area" label="最近登录地区"></el-table-column>
        <el-table-column prop="last_time" label="最近登录时间" width="200">
          <template slot-scope="scope">
            <el-tag type="success" effect="plain" v-if="scope.row.last_time">{{ $moment(scope.row.last_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</el-tag>
            <el-tag type="info" effect="plain" v-else>从未登录</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="350">
          <template slot-scope="scope">
            <el-button size="small" type="success" @click="viewItem(scope.row.m_id)">登录记录</el-button>
            <el-button size="small" @click="editItem(scope.row.m_id)">编辑</el-button>
            <el-button type="danger" size="small" @click="resetPd(scope.row.m_id)">修改密码</el-button>
            <el-button type="danger" size="small" @click="delItem(scope.row.m_id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加账号弹窗 -->
    <el-dialog title="添加教师账号" :visible.sync="addVisible" width="40%" top="4vh" :before-close="addClose">
      <el-form :model="addForm" :rules="addRules" ref="addRef" class="add-form" label-position="top">
        <el-form-item label="手机号" prop="username">
          <el-input v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="绑定到校区:" prop="campus">
          <el-select v-model="addForm.campus" placeholder="请选择校区，不选择则不限制">
            <el-option label="无" value=""></el-option>
            <el-option v-for="item in campusList" :key="item.m_id" :label="item.title" :value="item.m_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-top: 30px;">
          <el-button type="primary" @click="addSubmit('addRef')">新增账号</el-button>
          <el-button @click="cancellAdd">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改账号弹窗 -->
    <el-dialog title="修改教师账号" :visible.sync="editVisible" width="60%" top="4vh" :before-close="editClose">
      <el-form :model="editForm" :rules="editRules" ref="editRef" class="edit-form" label-position="top">
        <el-form-item label="手机号" prop="username">
          <el-input v-model="editForm.username"></el-input>
        </el-form-item>
        <el-form-item label="绑定到校区:" prop="campus">
          <el-select v-model="editForm.campus" placeholder="请选择校区，不选择则不限制">
            <el-option label="无" value=""></el-option>
            <el-option v-for="item in campusList" :key="item.m_id" :label="item.title" :value="item.m_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-top: 30px;">
          <el-button type="primary" @click="editSubmit('editRef')">修改账号</el-button>
          <el-button @click="cancellEdit">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 查看帐号登录记录 -->
    <el-dialog title="登录记录" :visible.sync="viewVisible" width="1000px"  max-height="600" :before-close="viewClose">
      <el-table :data="logList" border style="width: 100%">
        <el-table-column fixed prop="id" label="ID" width="100"></el-table-column>
        <el-table-column prop="time" label="登录时间">
          <template slot-scope="scope">
            <el-tag type="success" effect="plain">{{ $moment(scope.row.time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="area" label="登录地区" width="120"></el-table-column>
        <el-table-column prop="ip" label="登录IP" width="120"></el-table-column>
        <el-table-column prop="out_time" label="退出时间">
          <template slot-scope="scope">
            <el-tag type="success" effect="plain" v-if="scope.row.out_time">{{ $moment(scope.row.out_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</el-tag>
            <el-tag type="danger" effect="plain" v-else>非正常退出</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="out_area" label="退出地区" width="120"></el-table-column>
        <el-table-column prop="out_ip" label="退出IP" width="120"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 修改账号密码弹窗 -->
    <el-dialog title="修改教师账号登录密码" :visible.sync="editPwdVisible" width="400px" top="4vh" :before-close="editPwdClose">
      <el-form :model="editPwdForm" :rules="editPwdRules" ref="editPwdRef" class="edit-form" label-position="top">
        <el-form-item label="新密码" prop="password">
          <el-input v-model="editPwdForm.password"></el-input>
        </el-form-item>
        <el-form-item style="margin-top: 30px;">
          <el-button type="primary" @click="editPwdSubmit('editPwdRef')">提交修改</el-button>
          <el-button @click="cancelPwdEdit">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Teacher',
  data () {
    return {
      // 校区列表
      campusList: [],
      query: {
        title: ''
      },
      dataList: [],
      addForm: {
        username: '',
        password: '',
        campus: ''
      },
      addRules: {
        username: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 5, max: 11, message: '长度在 5 到 11 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        campus: [
          { required: true, message: '请绑定校区', trigger: 'blur' }
        ]
      },
      // 添加弹窗
      addVisible: false,
      editForm: {
        m_id: '',
        username: '',
        campus: ''
      },
      editRules: {
        username: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 5, max: 11, message: '长度在 5 到 11 个字符', trigger: 'blur' }
        ],
        campus: [
          { required: true, message: '请绑定校区', trigger: 'blur' }
        ]
      },
      // 添加弹窗
      editVisible: false,
      // 登录记录
      logList: [],
      // 查看登录记录
      viewVisible: false,
      // 修改密码
      editPwdForm: {
        m_id: '',
        password: ''
      },
      editPwdRules: {
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符之间', trigger: 'blur' }
        ],
        m_id: [
          { required: true, message: '获取用户ID失败，请刷新页面后重试', trigger: 'blur' }
        ]
      },
      editPwdVisible: false
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 重置密码
    async resetPd (mid) {
      this.editPwdForm.m_id = mid
      this.editPwdVisible = true
    },
    // 获取校区列表
    async getCampusList () {
      const { data: res } = await this.$http.get('/campus-list')
      if (res.code === 200) {
        this.campusList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取已添加教师账号列表
    async getDataList () {
      const { data: res } = await this.$http.get('teacher-list', { params: this.query })
      if (res.code === 200) {
        this.dataList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭弹窗
    addClose (done) {
      this.$refs.addRef.resetFields()
      done()
    },
    // 点击添加帐号按钮
    async addUser () {
      await this.getCampusList()
      this.addVisible = true
    },
    // 添加账号
    addSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('teacher/0', this.addForm)
          if (res.code === 200) {
            await this.getDataList()
            this.$refs.addRef.resetFields()
            this.addVisible = false
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消添加账号
    cancellAdd () {
      this.$refs.addRef.resetFields()
      this.addVisible = false
    },
    // 关闭弹窗
    editClose (done) {
      this.$refs.editRef.resetFields()
      done()
    },
    // 编辑账号
    async editItem (mId) {
      await this.getCampusList()
      const { data: res } = await this.$http.get('teacher/' + mId)
      if (res.code === 200) {
        this.editForm.m_id = res.data.m_id
        this.editForm.username = res.data.username
        this.editForm.campus = res.data.campus
        this.editVisible = true
      } else {
        this.$message.error(res.msg)
      }
    },
    // 删除账号
    async delItem (id) {
      const { data: res } = await this.$http.delete('teacher/' + id)
      if (res.code === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 修改账号
    editSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('teacher/' + this.editForm.m_id, this.editForm)
          if (res.code === 200) {
            await this.getDataList()
            this.$refs.editRef.resetFields()
            this.editVisible = false
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消添加账号
    cancellEdit () {
      this.$refs.editRef.resetFields()
      this.editVisible = false
    },
    // 查看登录记录
    async viewItem (mid) {
      const { data: res } = await this.$http.get('log-list', { params: { m_id: mid } })
      if (res.code === 200) {
        this.logList = res.data
        this.viewVisible = true
      } else {
        this.$message.error(res.msg)
      }
    },
    // 查看登录记录关闭
    viewClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.logList = []
          this.viewVisible = false
          done()
        })
        .catch(_ => {})
    },
    // 将时间转换为天
    toTime (machtime) {
      if (machtime > 0) {
        const s = Math.floor(machtime / 1) % 60
        machtime = Math.floor(machtime / 60)
        const i = machtime % 60
        machtime = Math.floor(machtime / 60)
        const h = machtime % 24
        machtime = Math.floor(machtime / 24)
        const d = machtime
        return d + ' 天 ' + h + ' 时 ' + i + ' 分 ' + s + ' 秒 '
      } else {
        return '未登录'
      }
    },

    // 修改账号密码
    editPwdSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/reset-teacher', this.editPwdForm)
          if (res.code === 200) {
            this.$refs.editPwdRef.resetFields()
            this.editPwdVisible = false
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消修改账号密码
    cancelPwdEdit () {
      this.$refs.editPwdRef.resetFields()
      this.editPwdVisible = false
    },
    // 关闭修改账号密码弹窗
    editPwdClose (done) {
      this.$refs.editPwdRef.resetFields()
      done()
    }
  }
}
</script>

<style scoped>
.teacher{
  width: 100%;
}
.page-content{
  margin-top: 20px;
  width: 100%;
}
.search-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-form-inline .el-form-item{
  margin-bottom: 0;
}
.add-form{
  padding: 0 40px;
  text-align: left;
}
.edit-form{
  padding: 0 40px;
  text-align: left;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
.el-form-item{
  margin-bottom: 8px;
}
.el-descriptions{
  padding: 0 40px;
}
</style>
